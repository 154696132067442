$(function () {
  var IScroll = require("iscroll");
  var $ = require("jquery");
  var winW = $(window).width();
  var devW = 767;
  require("jquery-drawer");
  $(document).ready(function () {
    $(".drawer").drawer();
  });

  // 開閉を自力で実装
  var scrollPos;
  $("body").on("click", function () {
    if ($('body').hasClass("drawer-close")) {
      $(".drawer-hamburger-icon").removeClass("drawer-open-button");
    }
  })
  $("#drawer-menu-button").on("click", function () {
    if ($(".drawer").hasClass("drawer-close")) {
      $(".drawer").removeClass("drawer-close");
      $(".drawer").addClass("drawer-open");
      $(".drawer-hamburger-icon").addClass("drawer-open-button");
      scrollPos = $(window).scrollTop();
      $("body").addClass("fixed").css({ top: -scrollPos });
      //768pxより小さい時の処理
      if (winW <= devW) {
        $(".drawer-nav").css("overflow", "scroll");
      };
    } else {
      $(".drawer-hamburger-icon").removeClass("drawer-open-button");
      $(".drawer").removeClass("drawer-open");
      $(".drawer").addClass("drawer-close");
      $("body").removeClass("fixed").css({ top: 0 });
      window.scrollTo(0, scrollPos);
      //768pxより小さい時の処理
      if (winW <= devW) {
        $(".drawer-nav").css("overflow", "");
      };
    }
  });
  $(".drawer-menu-item").on("click", function () {
    if ($(this).attr("aria-expanded") == "false") {
      $(this).attr("aria-expanded", true);
    } else {
      $(this).attr("aria-expanded", false);
    }
    $(this).parents(".drawer-dropdown").toggleClass("open");
    //768pxより大きい時の処理
    if (winW > devW) {
      this.iScroll = new IScroll(".drawer-nav", {
        mouseWheel: true,
        preventDefault: false,
      });
    };
  });
  $(".drawer-dropdown > a").click(function () {
    if (!$(this).hasClass("parent-clicked")) {
      $(this).addClass("parent-clicked");
      return false;
    } else {
      location.href = this.href;
    }
  });
});
